<template>
  <tr>
    <td v-if="config.config.fieldsSelected">
      <v-checkbox v-model="props.props.selected" primary hide-details></v-checkbox>
    </td>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="text-xs-left" style="padding: 0 10px !important;">
      <v-list class="talk-toolbar__title" two dense flat style="padding-top: 0px;">
        <v-list-tile avatar flat class="talk-toolbar__title-text">
          <v-list-tile-avatar>
            <v-avatar size="45">
              <img v-if="props.item.file && props.item.file.url" :src="props.item.file.url">
              <img v-else-if="props.item.type == 1" src="static/icons/user_icon_1.png">
              <img v-else src="static/icons/monitor/user-groups-96.png">
            </v-avatar>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              <span v-if="props.item" style="font-size: 18px;">
                {{props.item.name | stringlength(35)}}
              </span>
              <span v-else style="font-size: 18px;">Nome não definido</span>
            </v-list-tile-title>
            <v-list-tile-sub-title>
              <v-tooltip v-if="props.item.checked == 2" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="green" small>
                    verified
                  </v-icon>
                </template>
                <span>
                  Contato validado
                </span>
              </v-tooltip>
              <v-tooltip v-if="props.item.checked == 3" bottom lazy>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="red" small>
                    do_not_disturb_on
                  </v-icon>
                </template>
                <span>
                  Contato inválido
                </span>
              </v-tooltip>
              <span style="font-size: 12px; padding-left: 5px;">
                <span v-if="props.item.type == 1">
                  <span v-if="props.item.number.toString().length == 12">
                    <strong>{{ props.item.number | mask('+## (##) ####-####') }}</strong>
                  </span>
                  <span v-else-if="props.item.number.toString().length == 13">
                    <strong>{{ props.item.number | mask('+## (##) # ####-####') }}</strong>
                  </span>
                  <span v-else>
                    {{ props.item.number }}
                  </span>
                </span>
                <span v-else>- - -</span>
              </span>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </td>
    <td class="text-xs-left" style="padding: 0px !important; min-width: 70px;">
      <strong>#{{ props.item.id }}</strong>
    </td>
    <td v-if="props.item.type == 1" class="text-xs-left" style="padding: 0 10px !important;">
      <span v-if="props.item.person && props.item.person.name" v-text="props.item.person.name"></span>
      <span v-else>Não definido</span>
    </td>
    <td>
      <div v-if="config.mode != 'select'">
        <v-btn v-if="props.item.opened_talks && props.item.opened_talks.length > 0"
          outline
          small
          link
          :to="{name: 'talk-view-messages', params: {id: props.item.opened_talks[0].id}}"
        >Acessar Atendimento</v-btn>
        <v-btn v-else outline small @click="newTalk()">Novo Atendimento</v-btn>
      </div>
      <div v-else>- - - </div>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'TalkContacts',
    methods: {
      openTicket: function (ticketId) {
        this.$router.push({name: 'ticket', params: {id: ticketId}})
      },
      newTalk: function () {
        this.$WiApiPost({
          uri: 'talk/talk',
          data: {
            talk_contact_id: this.props.item.id,
            talk_channel_id: this.props.item.talk_channel_id,
            operator_id: this.$Auth.user().id,
            status: 2
          },
          config: {msgLoad: true, msgSucess: true},
          callback: (response) => {
            this.$router.push({name: 'talk-view-messages', params: {id: response.sucess.id}})
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>